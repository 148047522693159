import Container from "@/components/atoms/Container";
import Section from "@/components/atoms/Section";

const SpinnerScreen = ({children, className}) => {
    return <Section className={` ${className ? className : ''}`}>
        <Container className="h-screen justify-center items-center">
            <div>Loading page, please waiting ...</div>
        </Container>
    </Section>
}
export default SpinnerScreen;