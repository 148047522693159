export const findInArrayByKey = (arr, key, val) => {
    return arr.filter((el, id) => el[key] === val)
}

const _isValidTimestamp = (value) => {
    const date = new Date(value);
    return date instanceof Date && !isNaN(date);
}

// for presenting data time
// export const getDate1 = (moreDays, format, fromDate, timezone = "+02:00") => {
//     /* if fromDate then moreDays should be null */
//     /**
//      * 1. zwraca datę z liczbą dni do przodu - more days
//      * 2. zwraca datę przeformatowaną z innej daty - format - fromDate
//      * 
//      */
//     // convert date dd.mm.yyy to yyyy-mm-dd

//     fromDate = /^[0-9]{10,13}$/.test(fromDate) ? parseInt(fromDate) : fromDate && fromDate.split(".") ? fromDate.split(".").reverse().join("-") : fromDate;
//     const today = fromDate ? new Date(fromDate) : new Date();
//     const futureDate = new Date(today.getTime() + moreDays * 24 * 60 * 60 * 1000);

//     const dayOfWeek = futureDate.getDay();

//     const dd = String(futureDate.getDate()).padStart(2, '0');
//     const mm = String(futureDate.getMonth() + 1).padStart(2, '0'); // Month index starts from 0
//     const yyyy = futureDate.getFullYear();

//     const options = { month: 'long', timeZone: timezone, };
//     const monthName = futureDate.toLocaleDateString('pl-PL', options);
//     const monthNameShort = monthName.substring(0, 3);

//     const weekPL = ['pn.', 'wt.', 'śr.', 'czw.', 'pt.', 'sb.', 'nd'];
//     const weekEN = ['mn.', 'tu.', 'we.', 'th.', 'fr.', 'sa.', 'sn.'];
//     const dayWeekShort = weekPL[dayOfWeek];

//     const hoursUTC = String(futureDate.getUTCHours()).padStart(2, '0');
//     const minutesUTC = String(futureDate.getUTCMinutes()).padStart(2, '0');
//     const secondsUTC = String(futureDate.getUTCSeconds()).padStart(2, '0');

//     const timeUTC = `${hoursUTC}:${minutesUTC}:${secondsUTC}`;
//     const timeUTCShort = `${hoursUTC}:${minutesUTC}`;


//     switch (format) {
//         case 1:
//             return `${yyyy}-${mm}-${dd}`;
//             break;
//         case 2:
//             return `${dd} ${monthNameShort} ${yyyy}`
//             break;
//         case 3:
//             return `${dd}.${mm}.${yyyy}`
//             break;
//         case 4:
//             return `${dd} ${monthName}`;
//             break;
//         case 5:
//             return `${dd} ${monthNameShort} ${yyyy}, ${dayWeekShort}`;
//             break;
//         case 6:
//             return `${dd} ${monthName} ${yyyy}`;
//             break;
//         case 7:
//             return `${timeUTC}`;
//             break;
//         case 8:
//             return `${yyyy}-${mm}-${dd}T${timeUTCShort}`;
//             break;
//         default:
//             return `${dd}.${mm}.${yyyy}`
//     }
// }
import { addDays, parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { enUS, pl } from 'date-fns/locale';

export const getDate = (moreDays, formatOption, fromDate, timeZone = 'Europe/Warsaw', includeTime = false, language = 'pl') => {
    let date;

    // Parsing the fromDate based on its format
    if (/^[0-9]{10,13}$/.test(fromDate)) {
        date = new Date(parseInt(fromDate));
    } else if (fromDate && fromDate.includes(".")) {
        date = parseISO(fromDate.split(".").reverse().join("-"));
    } else if (typeof fromDate === 'string') {
        date = new Date(fromDate);
    } else {
        date = new Date();
    }

    // Return an error message if the date is invalid
    if (isNaN(date.getTime())) return 'Invalid date provided';

    // Calculate the target date by adding more days
    const targetDate = addDays(date, moreDays);

    // Ensure the targetDate is valid
    if (isNaN(targetDate.getTime())) return 'Invalid target date calculated';

    // Define the locale based on the language parameter
    const locale = language === 'pl' ? pl : enUS;

    const monthName = formatInTimeZone(targetDate, timeZone, 'LLLL', { locale });
    const monthNameShort = monthName.substring(0, 3);
    const weekPL = language === 'pl' ? ['nd.', 'pn.', 'wt.', 'śr.', 'czw.', 'pt.', 'sb.'] : ['Su.', 'Mo.', 'Tu.', 'We.', 'Th.', 'Fr.', 'Sa.'];
    const dayOfWeekIndex = parseInt(formatInTimeZone(targetDate, timeZone, 'i', { locale }), 10) % 7;
    const dayWeekShort = weekPL[dayOfWeekIndex];

    const dd = formatInTimeZone(targetDate, timeZone, 'dd', { locale });
    const mm = formatInTimeZone(targetDate, timeZone, 'MM', { locale });
    const yyyy = formatInTimeZone(targetDate, timeZone, 'yyyy', { locale });
    const timeUTC = includeTime ? formatInTimeZone(targetDate, timeZone, 'HH:mm:ss', { locale }) : '';
    const timeUTCShort = includeTime ? formatInTimeZone(targetDate, timeZone, 'HH:mm', { locale }) : '';

    switch (formatOption) {
        case 1:
            return `${yyyy}-${mm}-${dd}${includeTime ? `T${timeUTCShort}` : ''}`;
        case 2:
            return `${dd} ${monthNameShort} ${yyyy}${includeTime ? `, ${timeUTCShort}` : ''}`;
        case 3:
            return `${dd}.${mm}.${yyyy}${includeTime ? ` ${timeUTC}` : ''}`;
        case 4:
            return `${dd} ${monthName}${includeTime ? `, ${timeUTCShort}` : ''}`;
        case 5:
            return `${dd} ${monthNameShort} ${yyyy}, ${dayWeekShort}${includeTime ? `, ${timeUTCShort}` : ''}`;
        case 6:
            return `${dd} ${monthName} ${yyyy}${includeTime ? `, ${timeUTCShort}` : ''}`;
        case 7:
            return timeUTC;
        case 8:
            return `${yyyy}-${mm}-${dd}${includeTime ? `T${timeUTCShort}` : ''}`;
        case 9:
            return language === 'pl'
                ? `do dnia ${dd}.${mm}.${yyyy}${includeTime ? `, do godziny ${timeUTCShort}` : ''}`
                : `until ${dd}.${mm}.${yyyy}${includeTime ? `, until ${timeUTCShort}` : ''}`;
        case 10:
            return language === 'pl'
                ? `do ${dd}.${mm}.${yyyy}${includeTime ? `, godz. ${timeUTCShort}` : ''}`
                : `until ${dd}.${mm}.${yyyy}${includeTime ? `, at ${timeUTCShort}` : ''}`;
        default:
            return `${dd}.${mm}.${yyyy}${includeTime ? ` ${timeUTC}` : ''}`;
    }
};

export const countGuestsFromParam = (roomsString, countType = 'all', roomId = null) => {
    // roomString -> guests=1-2-3and4.4&rooms=3

    const rooms = roomsString.split('-'); // Rozdziela ciąg na pokoje

    let totalPeople = 0;

    rooms.forEach((room, index) => {
        // Jeśli roomId jest określone, przetwarzaj tylko pokój o tym id
        if (roomId !== null && index !== roomId) {
            return;
        }

        const parts = room.split('and');
        const adults = Number(parts[0]); // Liczba dorosłych
        const childrenAges = parts[1] ? parts[1].split('.') : []; // Tablica wieku dzieci, jeśli są

        switch (countType) {
            case 'all':
                totalPeople += adults + childrenAges.length; // Dodaje dorosłych i dzieci
                break;
            case 'adults':
                totalPeople += adults; // Dodaje tylko dorosłych
                break;
            case 'children':
                totalPeople += childrenAges.length; // Dodaje tylko dzieci
                break;
            default:
                console.log('Invalid count type');
        }
    });

    return totalPeople;
}

export const countGuestsFromArray = (people, countType = 'all') => {
    let count = 0;

    people.forEach(person => {
        if (countType === 'all' || countType === 'adults') {
            // Dodajemy liczbę dorosłych do licznika
            count += person.adults || 0;
        }
        if ((countType === 'all' || countType === 'children') && person.children) {
            // Dodajemy liczbę dzieci do licznika
            count += person.children.length;
        }
    });

    return count;
}

export const setGuestsToParams = (obj) => {
    let _guests = []
    obj.map((room, id) => {
        let __room = [];
        room.adult || room.adult > 0 ? __room.push(room.adult) : null;
        room.childrenArr.length > 0 ? __room.push(room.childrenArr.join('.')) : null;
        _guests.push(__room.join('and'))
    })
    return _guests.join('-');
}

export const showHumanGuests = (arr, ln18) => {
    const adult = countGuestsFromArray(arr, 'adults');
    const child = countGuestsFromArray(arr, 'children');

    return `${adult} ${_ln18('adults', ln18)}, ${child} ${_ln18('children', ln18)}`
}

export const calculateDaysFromRangeDates = (date1, date2) => {
    const startDate = new Date(date1);
    const endDate = new Date(date2);

    const diffTime = endDate.getTime() - startDate.getTime();

    const days = diffTime / (1000 * 3600 * 24);

    return days;
}

export const plRoomSpell = (rooms) => {
    const number = rooms;
    switch (true) {
        case number === 1:
            return 'pokój'
            break;
        case (number >= 2 && number <= 4):
            return 'pokoje';
            break;
        case (number >= 5):
            return 'pokoi';
            break;
        default:
            return 'pokój';
    }
}

export const plGuestsSpell = (guests) => {
    const number = guests;
    switch (true) {
        case number === 1:
            return 'gościa'
            break;
        case (number >= 2):
            return 'gości';
            break;
        default:
            return 'gości';
    }
}

export const plNightSpell = (night) => {
    const number = night;
    switch (true) {
        case number === 1:
            return 'noc'
            break;
        case (number >= 2 && number < 5):
            return 'noce';
            break;
        default:
            return 'nocy';
    }
}

// get lang value by key from lib
export const _ln18 = (key, lib, resultType) => {

    if (!resultType)
        resultType = 'value'
    // if (!lib || 'undefined' === lib)
    // lib = useLangContext().ln18

    // console.log(lib.lang.filter(obiekt => key.includes(obiekt.name)))
    // const obj = lib.lang.find((lnKey) => {
    //     if (typeof lnKey.name === 'string') {
    //         return lnKey.name === key
    //     } else
    //         if (typeof lnKey.name === 'object') {
    //             if (lnKey.name.indexOf(key) !== -1) {
    //                 return 'aa'
    //             }
    //         } else
    //             if (typeof lnKey.name === undefined) {
    //                 return key;
    //             }
    // });
    // return obj ? obj.val : key

    if (typeof key === 'string') {
        key = [key];
    }

    let found = lib.lang.filter(obiekt => {
        if (Array.isArray(obiekt.name)) {
            return obiekt.name.some(el => key?.includes(el));
        } else {
            return key?.includes(obiekt.name) || '';
        }
    });
    if (found.length === 1) {
        if (resultType === 'obj') return [found[0]];
        if (resultType === 'value') return found[0].val;
        if (resultType === 'key') return found[0].key;
    } else {
        // console.log(found.map(k => k))
        // return found.map(k => k);
        if (resultType === 'obj') return found.map(k => k);
        if (resultType === 'value') return found.map(k => k.val);
        if (resultType === 'key') return found.map(k => k.name);
    }
}

export const getImageSize = (strImage, w, h) => {
    if (typeof w !== 'number' || typeof h !== 'number') {
        return false
    }
    return strImage.replace('{size}', `${w}x${h}`)
}

export const sortArrayObjectsByKey = async (array, key, order = 'asc') => {
    const sortedArray = [...array].sort((a, b) => {
        if (a[key] < b[key]) {
            return order === 'asc' ? -1 : 1;
        }
        if (a[key] > b[key]) {
            return order === 'asc' ? 1 : -1;
        }
        return 0;
    });
    return sortedArray;
};

export const filterObjectsByKey = async (array, key, string) => {
    const filteredObjects = array.filter(object =>
        object[key] && object[key].includes(string)
    );

    return filteredHotels;
}

export const updateVisibilityInArray = async (array, key, searchString) => {
    const updatedObjects = array.map(object => {
        const isVisible = object[key] && object[key].toLowerCase().includes(searchString.toLowerCase());
        // Tworzymy nowy obiekt z oryginalnymi właściwościami obiektu i dodajemy/aktualizujemy isVisible
        return { ...object, isVisible: isVisible };
    });

    return updatedObjects
}

export const updateVisibilityByKeyValue = (arr, keyToFind, searchString) => {
    // Sprawdzenie, czy arr jest tablicą
    if (!Array.isArray(arr)) {
        return false; // Zwraca false, jeśli nie można przeszukiwać arr
    }

    const searchLower = searchString.toLowerCase(); // Przekształcenie szukanego ciągu na małe litery raz
    let isUpdated = false;

    // Przeglądanie wszystkich obiektów w tablicy
    for (const obj of arr) {
        if (typeof obj !== "object" || obj === null) {
            continue; // Pomija nieprawidłowe obiekty
        }

        let found = false; // Flaga wskazująca, czy znaleziono dopasowanie w bieżącym obiekcie

        // Przeglądanie wszystkich kluczy i wartości w obiekcie
        for (const [key, value] of Object.entries(obj)) {
            // Sprawdzanie wartości jako string lub jako lista stringów
            if (key === keyToFind) {
                console.log(typeof value)
                if (typeof value === 'string' && value.toLowerCase().includes(searchLower)) {
                    obj.isVisible = true; // Ustawienie isVisible na true, jeśli wartość jest stringiem i zawiera szukany ciąg
                    found = true;
                } else if (Array.isArray(value)) { // Jeśli wartość jest tablicą
                    for (const item of value) {
                        if (typeof item === 'string' && item.toLowerCase().includes(searchLower)) {
                            obj.isVisible = true; // Ustawienie isVisible na true, jeśli element tablicy zawiera szukany ciąg
                            found = true;
                            break; // Przerywanie pętli po pierwszym dopasowaniu
                        }
                    }
                }
            }

            // Kontynuacja przeszukiwania, jeśli wartość jest obiektem
            if (typeof value === "object") {
                if (updateVisibilityByKeyValue([value], keyToFind, searchString)) {
                    found = true;
                }
            }
        }

        if (!found) {
            obj.isVisible = false; // Ustawienie isVisible na false, jeśli nie znaleziono dopasowania
        }

        if (found) {
            isUpdated = true; // Zaznaczenie, że dokonano aktualizacji w którymkolwiek obiekcie
        }
    }

    return isUpdated; // Zwraca true, jeśli dokonano jakiejś aktualizacji
};

export const updateStringArray = (arrayString, valueString, delimeter) => {
    let array = arrayString ? arrayString.split(delimeter) : [];
    const index = array.indexOf(valueString); // Szuka indeksu wartości w tablicy

    if (index === -1) {
        // Dodaje wartość, jeśli nie istnieje
        array.push(valueString);
    } else if (index > -1) {
        // Usuwa wartość, jeśli istnieje
        array.splice(index, 1);
    }
    return array.join(delimeter); // Zwraca zaktualizowaną tablicę jako ciąg znaków
}

export const calculateTotalAmount = (data) => {
    let totalAmount = 0;
    const payments = data.payment_types || data.daily_prices;

    payments.map(type => {
        totalAmount += parseFloat(type.show_amount_v3) || parseFloat(type);  // Dodaje kwotę z pola amount
        // type.cancellation_penalties.policies.forEach(policy => {
        //     totalAmount += parseFloat(policy.amount_charge);  // Dodaje kwotę z polityk anulowania
        // });
    });
    return totalAmount;
}

export const findValueByKey = (obj, keyToFind) => {
    if (typeof obj !== "object" || obj === null) {
        return undefined;
    }

    // Przeglądanie obiektów i tablic
    for (const [key, value] of Object.entries(obj)) {
        // Zwracanie wartości, jeśli klucz pasuje lub wartość jest równa kluczowi
        if (key === keyToFind || value === keyToFind) {
            return value;
        }

        // Kontynuacja przeszukiwania, jeśli wartość jest obiektem lub tablicą
        if (typeof value === "object") {
            const result = findValueByKey(value, keyToFind);
            if (result !== undefined) {
                return result;
            }
        }
    }

    // Jeśli żaden z kluczy nie pasuje, i nie znaleziono w zagnieżdżonych obiektach
    return undefined;
};

export const removeEmptyKeysFromObject = (obj) => {
    return Object.keys(obj).reduce((acc, key) => {
        if (obj[key] !== '' && obj[key] !== null && obj[key] !== undefined) {
            acc[key] = obj[key];
        }
        return acc;
    }, {});
};

export const checkIfImageExists = (url, callback) => {
    const img = new Image();
    img.src = url;

    if (img.complete) {
        callback(true);
    }
    else {
        img.onload = () => {
            callback(true);
        };

        img.onerror = () => {
            callback(false);
        };
    }
}